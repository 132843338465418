import React, { useState } from 'react'
import { usePathname } from '@core/navigation'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'
import InlineCSS from '@components/seo/InlineCSS'

/* Inline Styles */
import HeaderStyles from '!raw-loader!postcss-loader!sass-loader!./RlxCpoHeader.module.scss'

/* Rolex CPO Path */
import {
	rolexCPOPath,
	rolexCPOName,
	rolexCPOOurSelection,
} from '@hooks/useIsRolex'

const RlxCpoHeader = (): React.ReactElement => {
	const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
	const currentPath = usePathname()

	/* Nav Links - folder will be inserted in each link */
	const navLinks = [
		{
			href: rolexCPOPath,
			label: `${rolexCPOName} at The 1916 Company`,
		},
		{
			href: `${rolexCPOPath}our-selection/`,
			label: rolexCPOOurSelection,
		},
		{
			href: `${rolexCPOPath}program/`,
			label: 'The Program',
		},
		{
			href: `${rolexCPOPath}certification/`,
			label: 'The Rolex Certification',
		},
		{
			href: `${rolexCPOPath}contact-us/`,
			label: 'Contact Us',
		},
	]

	/* Local Helpers */
	const toggleMobileMenu = () => {
		setIsMobileNavOpen((prevIsOpen) => !prevIsOpen)
	}
	const closeMobileMenu = () => {
		setIsMobileNavOpen(false)
	}

	return (
		<InlineCSS componentName={'RlxCpoHeader'} styles={HeaderStyles}>
			<header className={'rlx-cpo-v7-header'}>
				<div
					rawclassname="rlx-v7-container"
					className={`rlx-cpo-v7-header__main`}
				>
					<SwitchLink href={rolexCPOPath} className={'rlx-cpo-v7-header__logo'}>
						<img
							src="https://the1916company.imgix.net/CPOV7/logos/Rolex_Logo_CPO_Arabesque_white.svg?auto=format&w=120"
							width="120"
							height="60"
							alt={rolexCPOName}
							rawclassname="rlx-v7-img"
						/>
					</SwitchLink>

					<button
						rawclassname="u-hidden-rlx-v7-xl"
						className={`rlx-cpo-v7-header__menu-btn ${
							isMobileNavOpen ? 'rlx-cpo-v7-header__menu-btn--active' : ''
						}`}
						onClick={toggleMobileMenu}
					>
						<span>Menu</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="#fff"
							width="12"
							height="12"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							version="1.1"
							x="0px"
							y="0px"
							viewBox="0 0 15 15"
							xmlSpace="preserve"
						>
							<path
								xmlns="http://www.w3.org/2000/svg"
								id="icons_x2F_play"
								d="M7.5,11.6L0,3.4h15L7.5,11.6z"
							></path>
						</svg>
					</button>

					<nav
						rawclassname={
							isMobileNavOpen
								? ''
								: 'u-hidden-rlx-v7-mobile u-hidden-rlx-v7-tablet u-hidden-rlx-v7-desktop'
						}
						className={`rlx-cpo-v7-header__nav rlx-cpo-v7-nav`}
					>
						<ul className={'rlx-cpo-v7-nav__list'}>
							{navLinks.map((link) => (
								<li key={link.label}>
									<SwitchLink
										onClick={closeMobileMenu}
										href={link.href}
										className={`rlx-cpo-v7-nav__link ${
											link.href === currentPath
												? 'rlx-cpo-v7-nav__link--active'
												: ''
										}`}
									>
										{link.label}
									</SwitchLink>
								</li>
							))}
						</ul>
					</nav>
				</div>
			</header>
		</InlineCSS>
	)
}

export default RlxCpoHeader
