import { useEffect, useState } from 'react'

/* Helpers */
import { fetchSafely } from '@helpers/fetchHelper'
import { getSFRAEndpoint } from '@helpers/sfraHelper'

/* Hooks */
import { useDetectHuman } from '@hooks/useDetectHuman'
import useEinstein from '@salesforce/retail-react-app/app/hooks/use-einstein'

import type { ExtendedScapiProduct } from '@lib/types/scapi/product.models'
import { sendGTAGEvent } from '@lib/helpers/hooksHelper'
import { generateProductGtagItems } from '@lib/helpers/productHelper'

/* Local Types */
type BasketDataType = object | null

export const useBasket = (): [
	BasketDataType,
	React.Dispatch<React.SetStateAction<BasketDataType>>,
	() => void,
	(
		product: ExtendedScapiProduct,
		price?: number
	) => Promise<{ success: boolean }>,
] => {
	const [basket, setBasket] = useState<BasketDataType>(null)
	const isHuman = useDetectHuman()
	const einstein = useEinstein()

	const fetchBasket = async () => {
		const data = await fetchSafely(getSFRAEndpoint('Headless-Bag'))
		if (data) {
			setBasket(data)
		}
	}

	useEffect(() => {
		isHuman && fetchBasket()
	}, [isHuman])

	/**
	 * Re-request customer basket with updated data
	 */
	const refreshBasket = () => {
		fetchBasket()
	}

	/**
	 * Add an item to the current basket
	 * @param price Must be external since price fields on product might be missing conversions local to the function implementation
	 */
	const addToBasket = async (product: ExtendedScapiProduct, price?: number) => {
		const basketUpdate = {
			success: false,
		}

		const data = await updateBasket(product.id)

		if (data) {
			/* Update Basket Context */
			setBasket(data)

			/* PWA Tracking */
			// TS error on einstein.sendAddToCart
			einstein.sendAddToCart([
				{
					productId: product.id,
					price: price || 0,
					quantity: 1,
				},
			])

			/* GTAG Event */
			sendGTAGEvent('add_to_cart', {
				ecommerce: {
					currency: product.currency,
					value: price || 0,
					items: generateProductGtagItems(product),
				},
			})

			basketUpdate.success = true
		}

		return basketUpdate
	}

	return [basket, setBasket, refreshBasket, addToBasket]
}

export const updateBasket = async (
	pid: string,
	qty: number = 1
): Promise<BasketDataType | false> => {
	const params = {
		pid,
		qty: (qty ?? 1).toString(),
	}

	return await fetchSafely(getSFRAEndpoint('Headless-Bag', undefined, params))
}
