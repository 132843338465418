import axios from 'axios'
import {
	EventType,
	ExtendedTMAOFormSchema,
	ExtendedTInquireFormSchema,
} from '../muleHelper.models'

export const postToMulesoft = async (
	eventType: EventType,
	data: ExtendedTMAOFormSchema | ExtendedTInquireFormSchema
) => {
	return axios.post(`/api/${eventType}`, {
		data: data,
	})
}
