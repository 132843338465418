import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'

/* Components */
import InlineCSS from '@components/seo/InlineCSS'

/* Helpers */
import { setCookie } from '@helpers/cookieHelper'
import { scrubLinks } from '@helpers/scrubHelper'

/* Hooks */
import {
	usePromos,
	PROMO_COOKIE_NAME,
	PROMO_DISMISS_DAYS,
	PromoVariantType,
} from '@hooks/usePromos'

/* Inline Styles */
import PromoBannerStyles from '!raw-loader!postcss-loader!sass-loader!./PromoBanner.module.scss'

const PromoBanner = ({
	variant,
}: {
	variant: PromoVariantType
}): React.ReactElement | null => {
	/* Conditionally Add */
	const hasPromos = usePromos(variant)
	const render: boolean = hasPromos && hasPromos.length > 0
	const [show, setShow] = useState(false)

	/* Local Handlers */
	const setDismissed = () => {
		setCookie(
			PROMO_COOKIE_NAME,
			new Date().getTime().toString(),
			PROMO_DISMISS_DAYS
		)
	}

	/* Animate on load */
	useEffect(() => {
		if (render && !show) {
			setTimeout(() => {
				setShow(true)
			}, 1)
		}
	}, [render])

	/* Local Components */
	const getPromoBanner = (promo: string, index: number) => {
		return (
			<div key={index} className="promos__item">
				<span className="promos__item-text">{scrubLinks(parse(promo))}</span>
				<button
					className="promos__item-dismiss"
					onClick={setDismissed}
					aria-label={`Dismiss for ${PROMO_DISMISS_DAYS} days`}
				>
					<svg
						className="promos__item-dismiss-svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18.75 5.25L5.25 18.75"
							stroke="black"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M18.75 18.75L5.25 5.25"
							stroke="black"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>
		)
	}

	return render ? (
		<InlineCSS componentName={'PromoBanner'} styles={PromoBannerStyles}>
			<div className={`promos ${show ? 'promos--show' : ''}`}>
				{hasPromos.map((promo, index) => getPromoBanner(promo, index))}
			</div>
		</InlineCSS>
	) : null
}

export default PromoBanner
