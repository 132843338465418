import { getAppOrigin } from '@salesforce/pwa-kit-react-sdk/utils/url'

/* Get SFRA Controller Endpoint URL */
export const getSFRAEndpoint = (
	controller: string,
	locale?: string,
	vars?: { [key: string]: string }
) => {
	locale = (locale || 'en_US').replace('-', '_')
	let ret: string =
		getAppOrigin() +
		'/mobify/proxy/ocapi/on/demandware.store/Sites-ns-company-Site/' +
		locale +
		'/' +
		controller
	if (vars) {
		ret +=
			'?' +
			Object.keys(vars)
				.map((key) => key + '=' + vars[key])
				.join('&')
	}
	return ret
}

/* Determine if a URL is a known SFRA Route */
export const isKnownSFRA = (href?: string): boolean => {
	/* No link? */
	if (!href) {
		return false
	}

	/* Known SFRA Routes */
	const routesSFRA = [
		/* Catalog */
		'rolex',

		/* Header */
		'account',
		'login',
		'wishlist',
		'bag',
		'checkout',
		'live',

		/* Footer */
		'sell-and-trade',
		'sell-your-watch',
		'trade-your-watch',
		'sell-quote',
		'trade-quote',
		'warranties',
		'contact-us',

		/* About Us Pages */
		'about-us/our-team',
		'about-us/our-locations',
		'about-us/our-locations/multi-brand-showrooms',
	]

	/* Determine up-level path for legacy WP & content asset links */
	let safeHref: string = href
	if (safeHref.indexOf('..') > -1) {
		;['watches', 'pre-owned'].forEach((route) => {
			safeHref = safeHref.replace(`/${route}/../`, `/`)
		})
	}

	/* Determine top-level path for relative links */
	if (safeHref.indexOf('../../') > -1) {
		safeHref = '/' + safeHref.split('../../').pop() + '/' || ''
		safeHref = safeHref.replace(/\.\.\//g, '/').replace(/\/{2,}/g, '/')
	}

	/* SFRA Route? */
	return routesSFRA.some((route) => safeHref.startsWith(`/${route}/`))
		? true
		: false
}
