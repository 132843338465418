/* Helpers */
import { getSFCCPrefix } from '../routeHelper'
import { newOrPreOwned } from '../watchesHelper'
import { getMutatedID } from '../muleHelper/hooks/usePostToMulesoft'
import { randomUUID } from '../cryptoHelper'

/* Shared Types */
import { CCategories, ScapiProduct } from '@lib/types/scapi/product.models'
import { GalleryImage } from '@lib/types/watch-detail-page/WDPGallery.models'
import { RlxImageData } from '@lib/types/third-party/Rolex.models'

/* Local Types */
interface NullsType {
	[key: string]:
		| string
		| string[]
		| number
		| undefined
		| boolean
		| null
		| unknown
}

/* Named functions */
export const parseProductImages = (imageList?: string): GalleryImage[] => {
	let ret: GalleryImage[] = []

	if (imageList) {
		try {
			ret = JSON.parse(imageList).map((image: { url: string; alt: string }) => {
				return {
					id: randomUUID(),
					type: 'image',
					src: new URL(image.url).href,
					alt: image.alt,
				}
			})
		} catch (e) {
			ret = []
		}
	}
	return ret
}

export const parseCpoProductImages = (imageList?: string): GalleryImage[] => {
	if (imageList) {
		/* CPO Image Alt Tags */
		const PDP_IMAGE_VIEWS: string[] = [
			'CPO Black',
			'360-1',
			'360-5',
			'360-15',
			'360-25',
		]

		try {
			const images = JSON.parse(imageList)
			const productImages = PDP_IMAGE_VIEWS
				// Limit array to images found on the product and in the config
				.filter((imgKey) => {
					return images.some(
						(imgData: RlxImageData) => imgData.Image_Type === imgKey
					)
				})
				// Convert array to parseProductImages GalleryImage[] format (see above)
				.map((imgKey) => {
					const img = images.find(
						(imgData: RlxImageData) => imgData.Image_Type === imgKey
					)

					return {
						id: randomUUID(),
						type: 'image' as const,
						src: new URL(img.Image_Small).href,
						alt: '',
						width: 3000,
						height: 3000,
					}
				})

			if (productImages.length) {
				return productImages
			} else {
				throw new Error('No CPO product images')
			}
		} catch (e) {
			console.error(e)
		}
	}

	return []
}

export const parseCpo360Images = (imageList?: string): string[] | void => {
	if (imageList) {
		try {
			const images = JSON.parse(imageList)

			const urls = images
				.filter((img: RlxImageData) => img.Image_Type.indexOf('360') > -1)
				.sort((a: RlxImageData, b: RlxImageData) => {
					if (a.Image_Order < b.Image_Order) {
						return -1
					} else if (a.Image_Order > b.Image_Order) {
						return 1
					}

					return 0
				})
				.map((img: RlxImageData) => {
					const url = new URL(img.Image_Small)

					url.searchParams.append('auto', 'format,compress')
					url.searchParams.append('w', '1200')
					url.searchParams.append('q', '30')

					return url.toString()
				})

			if (urls && urls.length) {
				return urls
			}
		} catch (e) {
			console.error(e)
		}
	}
}

export const isBaseProduct = (product: ScapiProduct): boolean => {
	return (
		product.master?.masterId === product.id &&
		(!product.variants || product.variants.length === 0)
	)
}

export const getVariantPid = (product: ScapiProduct): string => {
	const isBaseVariant: boolean = product.type?.master ?? false
	const variantPid: string = isBaseVariant
		? product.variants?.[0].productId || ''
		: ''

	return variantPid
}

export const getBrandId = (
	pdpType: string,
	categories: CCategories
): string => {
	let brandId: string = ''

	if (categories && Object.keys(categories).length > 0) {
		if (pdpType in categories && categories[pdpType].length > 1) {
			const brandIdReturned: string = categories[pdpType][1]
			brandId = getSFCCPrefix(pdpType, brandIdReturned) + brandIdReturned
		}
	}

	return brandId
}

export const handleNulls = (obj: NullsType): NullsType => {
	return Object.keys(obj).reduce((acc, key) => {
		if (typeof obj[key] !== 'undefined') {
			acc[key] = obj[key] || ''
		}
		return acc
	}, {} as NullsType)
}

export const generateProductGtagItems = (
	product: ScapiProduct,
	index?: number,
	plpParams?: {
		id?: string
		name?: string
		family?: string
		brand?: string
	}
): NullsType => {
	/* PLP Data */
	const plpId: string = plpParams?.id ?? ''
	const plpName: string = plpParams?.name ?? ''
	const plpFamily = plpParams?.family ?? ''
	const plpBrand = plpParams?.brand ?? ''

	/* PDP Data or PLP tile data */
	const {
		id,
		brand,
		c_brandFamily,
		name,
		price,
		currency,
		c_pdpType,
		c_style,
		productSku,
		c_sku,
		c_viewsDay,
		c_WatchYear,
		c_manufacturedIn,
		c_gender,
	} = product

	/* Convert to usable strings */
	const useId: string = plpId?.toString() || id?.toString() || ''
	const useName: string = plpName || name || ''
	const useBrand: string = plpBrand || brand || ''
	const useFamily: string = plpFamily || c_brandFamily || ''

	return handleNulls({
		item_id: useId,
		item_sku: getMutatedID(productSku || c_sku || useId),
		item_name: (useBrand + ' ' + useFamily).trim(),
		index: index || undefined,
		item_brand: useBrand || '',
		item_brandfamily: useFamily || '',
		item_status: newOrPreOwned(c_pdpType, useId),
		item_year: c_WatchYear || '',
		item_country: c_manufacturedIn || '',
		item_viewstoday: c_viewsDay || '',
		item_gender: c_gender || '',
		item_variant: c_style || '',
		item_title: useName || '',
		item_list_name: c_pdpType + ': ' + useBrand,
		price: price || undefined,
		currency: currency || undefined,
		quantity: 1,
	})
}

export const formatProductName = (
	name: string,
	brand: string,
	refNo: string
): string => {
	return name
		.replace(/Pre[-\s]?Owned/g, '')
		.replace(brand, '')
		.replace(refNo, '')
		.trim()
}

export const getBoxPapersValues = (product: ScapiProduct): string => {
	const ret: string[] = []

	/* Box & Papers, Box, Papers, empty */
	if (product?.c_hasBox) {
		ret.push('Box')
	}
	if (product?.c_hasPapers) {
		ret.push('Papers')
	}

	return ret.join(' & ')
}
