import React, { useState, useEffect, useContext, Suspense, lazy } from 'react'

/* Context */
import { SiteHeaderContext } from './SiteHeaderProvider'

/* Components */
import DesktopBanner from './DesktopBanner/DesktopBanner'
import MobileBanner from './MobileBanner/MobileBanner'
import InlineCSS from '@components/seo/InlineCSS'

/* Lazy Components */
const MiniHeader = lazy(() =>
	import('./MiniHeader/MiniHeader').catch(() => {
		return { default: () => <></> }
	})
)
const MobileMenu = lazy(() =>
	import('./MobileMenu/MobileMenu').catch(() => {
		return { default: () => <></> }
	})
)
const SearchMenu = lazy(() =>
	import('./SearchMenu/SearchMenu').catch(() => {
		return { default: () => <></> }
	})
)

/* Helpers */
import { getResource } from '@helpers/resourcesHelper'

/* Rolex CPO Path */
import { rolexCPOPath, useIsRolexCPO, rolexCPOName } from '@hooks/useIsRolex'

/* Shared Types */
import { TextItems, PopularItems, NavigationData } from '@lib/mock/nav.models'

/* Inline Styles */
import SiteHeaderStyles from '!raw-loader!postcss-loader!sass-loader!./SiteHeader.module.scss'
import RolexCPOStyles from '!raw-loader!postcss-loader!sass-loader!@rlx-design-system/v7/public/rlx-v7-global.scss'

/* TODO - move to popular.json pulled from SFCC download job */
const popular: PopularItems = [
	{
		name: 'Rolex',
		link: '/rolex/',
	},
	{
		name: rolexCPOName,
		link: rolexCPOPath,
	},
	{
		name: 'Patek Philippe',
		link: '/patek-philippe/',
	},
	{
		name: 'De Bethune',
		term: 'de bethune',
	},
	{
		name: 'F.P. Journe',
		term: 'f.p. journe',
	},
]

const SiteHeader = ({ data }: { data: NavigationData }): React.ReactElement => {
	/* Resources for DesktopBanner */
	const text: TextItems = Object.entries({
		locations: 'Locations',
		contact: 'Contact Us',
		account: 'Account',
		wishlist: 'Wishlist',
		search: 'Search',
		close: 'Close Menu',
		menu: 'Open Menu',
		popular: 'Popular',
		suggested: 'Suggested',
		placeholder: 'Search for brands or models',
		none: 'No results found',
		loading: 'Searching...',
	}).reduce(
		(acc, [key, value]) => {
			acc[key] = getResource('nav', key, value)
			return acc
		},
		{} as {
			[key: string]: string
		}
	)

	/* Miniheader State */
	const [renderMiniHeader, setRenderMiniHeader] = useState(false)
	const [showHeader, setShowHeader] = useState(false)

	/* MobileMenu & SearchMenu State */
	const { isMobileNavOpen, showSearch } = useContext(SiteHeaderContext)
	const [renderMobileMenu, setRenderMobileMenu] = useState(false)
	const [renderSearchMenu, setRenderSearchMenu] = useState(false)

	/* Is Rolex CPO? */
	const isRolexCPO: boolean = useIsRolexCPO()

	/* Render & show header on demand client side */
	useEffect(() => {
		const onScroll = () => {
			/* Scrolled >200px? */
			let shouldShow: boolean = window.scrollY > 200

			/* Fixed body offset by >200px? */
			if (!shouldShow) {
				const bodyTop: string = window
					.getComputedStyle(document.body)
					.getPropertyValue('top')
				const bodyTopInt: number =
					['0', '0px', 'auto'].includes(bodyTop) === false
						? parseInt(bodyTop)
						: 0
				shouldShow = bodyTopInt < -200
			}

			/* Render first time? */
			if (shouldShow && !renderMiniHeader) {
				setRenderMiniHeader(true)
			}

			/* Show now? */
			setShowHeader(shouldShow)
		}
		const stopWatching = () => {
			window.removeEventListener('scroll', onScroll)
		}

		stopWatching()
		window.addEventListener('scroll', onScroll, { passive: true })
		return () => stopWatching()
	}, [])

	/* Render MobileMenu on demand client side */
	useEffect(() => {
		/* Render first time? */
		if (!renderMobileMenu && isMobileNavOpen) {
			setRenderMobileMenu(true)
		}
	}, [isMobileNavOpen])

	/* Render SearchMenu on demand client side */
	useEffect(() => {
		/* Render first time? */
		if (!renderSearchMenu && showSearch) {
			setRenderSearchMenu(true)
		}
	}, [showSearch])

	return (
		<InlineCSS
			componentName={`SiteHeader`}
			styles={[SiteHeaderStyles, isRolexCPO ? RolexCPOStyles : ''].join(' ')}
		>
			{renderMiniHeader && (
				<Suspense fallback={<></>}>
					<MiniHeader data={data} showHeader={showHeader} />
				</Suspense>
			)}
			<div className={`main`} data-fixed-disable={'true'}>
				<header className={`header`} rawclassname={`header`}>
					<DesktopBanner data={data} text={text} />
					<MobileBanner text={text} />
				</header>
			</div>
			{renderMobileMenu && (
				<Suspense fallback={<></>}>
					<MobileMenu />
				</Suspense>
			)}
			{renderSearchMenu && (
				<Suspense fallback={<></>}>
					<SearchMenu text={text} popular={popular} />
				</Suspense>
			)}
		</InlineCSS>
	)
}

export default SiteHeader
