import { useEffect, useState } from 'react'

/* Global State */
import { setGlobalState, getGlobalState } from '@state/global'

/* Hooks */
import { useDetectHuman } from '@hooks/useDetectHuman'
import { useHasCookie } from '@hooks/useCookieHooks'
import { useIsWiderThan } from '@lib/hooks/useIsDesktop'

/* Helpers */
import { getPromo } from '@helpers/ocapiCustomAPIHelper'

/* Shared Types */
import { PromoResponse } from '../../app/api/promo'

/* Local Types */
type PromosType = string[]
export type PromoVariantType = 'desktop' | 'mobile'

/* Config */
export const PROMO_COOKIE_NAME: string = 'promo'
export const PROMO_DEFAULT_VALUE: string[] = []
export const PROMO_DISMISS_DAYS: number = 3

export const usePromos = (variant: PromoVariantType): PromosType => {
	/* Fetch once on first human detection */
	const [promos, setPromos] = useState<PromosType>(PROMO_DEFAULT_VALUE)
	const isHuman = useDetectHuman()

	/* Track if promo cookie is present */
	const hasPromoDismissed = useHasCookie(PROMO_COOKIE_NAME, undefined, true)

	/* Run once for desktop and/or mobile and share data */
	const isDesktop = useIsWiderThan(1200)

	useEffect(() => {
		if (isHuman) {
			if (hasPromoDismissed) {
				/* Dismissed by user? */
				setPromos(PROMO_DEFAULT_VALUE)
			} else {
				const existingPromos = getGlobalState()?.[
					PROMO_COOKIE_NAME
				] as PromosType
				if (existingPromos) {
					/* Existing from previous fetch */
					setPromos(existingPromos)
				} else if (
					(isDesktop && variant === 'desktop') ||
					(!isDesktop && variant === 'mobile')
				) {
					const fetchPromoData = async () => {
						const promoData = (await getPromo()) as PromoResponse | false
						if (promoData && promoData.promos) {
							const newPromos = promoData.promos
							setGlobalState({
								[PROMO_COOKIE_NAME]: newPromos,
							})
							setPromos(newPromos)
						}
					}

					/* Fetch once per session */
					fetchPromoData()
				}
			}
		}
	}, [isHuman, hasPromoDismissed, isDesktop])

	return promos
}
