const config = {
	plpTranslate: {
		'rlx-cpo': 'rolex-certified-pre-owned',
		rlx: 'rolex',
	},
	plpTranslateReverse: {
		'rolex-certified-pre-owned': 'rlx-cpo',
		rolex: 'rlx',
	},
	plpPrefix: {
		watches: '',
		'pre-owned': 'pre-owned-',
		'patek-philippe': 'patek-philippe-',
		'rolex-certified-pre-owned': 'rlx-cpo-',
		rolex: 'rlx-',
		jewelry: 'jewelry-',
	},
	plpSecondary: [
		'patek-philippe',
		'rolex-certified-pre-owned',
		'rolex',
		'jewelry',
	],
	plpAllBrands: ['watches', 'pre-owned', 'jewelry'],
	familyOverride: {
		tudor: {
			'tudor-royal': 'tudor-royal',
		},
		bulgari: {},
	},
	plpTranslateOddball: {
		'bvlgari-bvlgari': 'bvlgari-bvlgari-bvlgari',
	},
	plpSkips: ['rlx-rolex', 'rlx-cpo-rolex-certified-pre-owned'],
}

export const plpTranslate = config.plpTranslate as { [key: string]: string }
export const plpTranslateReverse = config.plpTranslateReverse as {
	[key: string]: string
}
export const plpPrefix = config.plpPrefix as { [key: string]: string }
export const plpSecondary = config.plpSecondary as string[]
export const plpAllBrands = config.plpAllBrands as string[]
export const familyOverride = config.familyOverride as {
	[key: string]: { [key: string]: string }
}
export const plpTranslateOddball = config.plpTranslateOddball as {
	[key: string]: string
}
export const plpSkips = config.plpSkips as string[]

export default config
