const rolexCPOName: string = 'Rolex Certified Pre-Owned'

const config = {
	watchesPerPage: 48,
	watchTileImageWidth: 740,
	searchCategories: ['watches', 'pre-owned', 'jewelry'],
	plpNames: {
		watches: 'New Watches',
		'pre-owned': 'Pre-Owned Watches',
		'patek-philippe': 'Patek Philippe',
		jewelry: 'Jewelry',
		'rolex-certified-pre-owned': rolexCPOName,
	},
	plpNamesMobile: {
		watches: 'New',
		'pre-owned': 'Pre-Owned',
		'patek-philippe': 'Patek Philippe',
		jewelry: 'Jewelry',
		'rolex-certified-pre-owned': rolexCPOName,
	},
	plpDirs: {
		search: 'SLP',
		watches: 'WLP',
		'pre-owned': 'ULP',
		rolex: 'BLP',
		'rolex-certified-pre-owned': 'CLP',
		jewelry: 'JLP',
	},
	specialTopLevel: ['patek-philippe'],
	rolexCPOPrefix: 'ns-cpo-',
	rolexBespokePrefix: 'rlx-',
	skuPrefix: {
		'pre-owned': 'ns-pre-',
		watches: 'ns-new-',
		'patek-philippe': 'ns-new-',
		jewelry: 'ns-j-',
		'rolex-certified-pre-owned': 'ns-cpo-',
	},
	prefixAdditions: {
		'pre-owned': 'ns-pre-',
		watches: 'ns-new-',
		'patek-philippe': 'ns-new-',
		jewelry: 'ns-j-',
		'rolex-certified-pre-owned': 'ns-cpo-',
	},
}

export const watchesPerPage = config.watchesPerPage as number
export const watchTileImageWidth = config.watchTileImageWidth as number
export const searchCategories = config.searchCategories as string[]
export const plpNames = config.plpNames as { [key: string]: string }
export const plpNamesMobile = config.plpNamesMobile as { [key: string]: string }
export const plpDirs = config.plpDirs as { [key: string]: string }
export const specialTopLevel = config.specialTopLevel as string[]
export const rolexCPOPrefix = config.rolexCPOPrefix as string
export const rolexBespokePrefix = config.rolexBespokePrefix as string
export const skuPrefix = config.skuPrefix as { [key: string]: string }
export const prefixAdditions = config.prefixAdditions as {
	[key: string]: string
}

export default config
