import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

/* Shared Types */
import {
	ExtendedTInquireFormSchema,
	ExtendedTMAOFormSchema,
	EventType,
} from '../muleHelper.models'
import {
	ScapiProduct,
	ExtendedScapiProduct,
} from '../../../types/scapi/product.models'
import { postToMulesoft } from '../api/api'

/* Local Types */
type PostToMulesoftOptions = {
	eventType: EventType
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSuccess: (data: AxiosResponse<any, any>) => void
	onError: (error: unknown) => void
}

export const getMutatedID = (id: string): string => {
	if (id) {
		let ret: string = id.toString()
		;[
			'ns-pre-',
			'ns-new-',
			'ns-cpo-',
			'rlx-cpo-',
			'rlx-',
			'ns-jp-',
			'ns-jn-',
			'ns-j-',
		].forEach((prefix) => {
			if (ret.indexOf(prefix) == 0) {
				ret = ret.replace(prefix, '')
			}
		})
		return ret
	} else {
		return ''
	}
}

export const getMutatedSKU = (sku?: string): string => {
	if (sku) {
		let ret: string = sku.toString() || ''
		/* Send sku with prefix removed or empty if sku is a refnum */
		if (ret.indexOf('ns-pre-') == 0) {
			ret = ret.replace('ns-pre-', '') // remove prefix for pre-owned
		} else if (ret.indexOf('ns-new-') == 0) {
			ret = '' // sku is a refnum
		} else if (ret.indexOf('ns-cpo-') == 0) {
			ret = ret.replace('ns-cpo-', '') // remove prefix for certified pre-owned
		} else if (ret.indexOf('rlx-cpo-') == 0) {
			ret = ret.replace('rlx-cpo-', '') // remove prefix for certified pre-owned (legacy prefix just in case)
		} else if (ret.indexOf('rlx-') == 0) {
			ret = '' // sku is a refnum
		} else if (ret.indexOf('ns-jp-') == 0) {
			ret = '' // sku is a refnum
		} else if (ret.indexOf('ns-jn-') == 0) {
			ret = '' // sku is a refnum
		} else if (ret.indexOf('ns-j-') == 0) {
			ret = '' // sku is a refnum
		}
		// if no prefix, return the sku as is
		return ret
	} else {
		return ''
	}
}

export const getMutatedProductIdAndSku = (
	product?: ScapiProduct | ExtendedScapiProduct
) => {
	/* No product data? */
	if (!product) {
		return {
			useId: '',
			useSku: '',
		}
	}

	/* Get Product ID & SKU */
	let id: string = product.id || ''
	let sku: string = product.productSku || ''

	/* Variant Check */
	if (product.type?.master) {
		if (product.variants && product.variants.length > 0) {
			const variant = product.variants[0]
			id = variant?.productId || id
			sku = variant?.variationValues?.sku || sku
		}
	}

	/* Return Mutated ID & SKU */
	return {
		useId: getMutatedID(id),
		useSku: getMutatedSKU(sku),
	}
}

export const usePostToMulesoft = ({
	eventType,
	onSuccess,
	onError,
}: PostToMulesoftOptions) => {
	return useMutation({
		mutationFn: (data: ExtendedTMAOFormSchema | ExtendedTInquireFormSchema) =>
			postToMulesoft(eventType, data),
		onSuccess(data) {
			onSuccess(data)
		},
		onError(error) {
			onError(error)
		},
	})
}
