import React, { useRef, useEffect, useState, lazy, Suspense } from 'react'

/* Components */
import { Btn } from '@components/Btn/Btn'
import InlineCSS from '@components/seo/InlineCSS'

/* Lazy Components */
const ContactHubModal = lazy(() =>
	import('./ContactHubModal').catch(() => {
		return { default: () => <></> }
	})
)

/* Hooks */
import { useToggle } from '@lib/hooks/useToggle'

/* Styles */
import ContactHubStyles from '!raw-loader!postcss-loader!sass-loader!./ContactHub.module.scss'

const ContactHubLazy = (): React.ReactElement => {
	/* State */
	const [isContactHubOpen, toggleContactHubOpen] = useToggle(false)
	const [render, setRender] = useState(false)

	/* Ref */
	const contactHubRef = useRef<HTMLDivElement>(null)

	/* Button Clicked - render Modal on first click */
	const buttonClicked = () => {
		if (!render) {
			setRender(true)
		}
		toggleContactHubOpen()
	}

	/* Listen for external event */
	useEffect(() => {
		document.body?.addEventListener('hub', buttonClicked)
		return () => {
			document.body?.removeEventListener('hub', buttonClicked)
		}
	}, [])

	/* Fade in on render */
	useEffect(() => {
		setTimeout(() => {
			if (contactHubRef.current) {
				contactHubRef.current.classList.add('ContactHub_ti-launcher--show')
			}
		}, 50)
	}, [])

	return (
		<InlineCSS componentName={`ContactHub`} styles={ContactHubStyles}>
			<div className={'ti-launcher'} ref={contactHubRef}>
				<Btn
					id="contactHub"
					variant={`icon`}
					handleClick={buttonClicked}
					className={'ti-launcher__button'}
					ariaLabel={`Open Contact Hub`}
				>
					<span></span>
				</Btn>
				{render && (
					<Suspense fallback={<></>}>
						<ContactHubModal
							isOpen={isContactHubOpen}
							toggleModal={toggleContactHubOpen}
						/>
					</Suspense>
				)}
			</div>
		</InlineCSS>
	)
}

export default ContactHubLazy
