import { usePathname } from '@core/navigation'

/* Rolex Values */
export const rolexAnyPath: string = '/rolex' // Any Rolex page

/* CPO Values */
export const rolexCPOPath: string = '/rolex-certified-pre-owned/' // Rolex CPO folder
export const rolexCPOPrefix = 'rlx-cpo-'
export const rolexCPOName: string = 'Rolex Certified Pre-Owned'
export const rolexCPOBrand: string = 'Rolex'
export const rolexCPOOurSelection: string = 'Our Selection'

/* Bespoke Values */
export const rolexBespokePath: string = '/rolex/' // Rolex Bespoke folder
export const rolexBespokeName: string = 'Rolex'

/* Local Helpers */
const getMatchingPath = (path: string): boolean => {
	const pathName: string = usePathname()
	return pathName.startsWith(path)
}

/* Named Exports */
export const useIsRolexPage = (): boolean => {
	return getMatchingPath(rolexAnyPath)
}
export const useIsRolexCPO = (): boolean => {
	return getMatchingPath(rolexCPOPath)
}
export const useIsRolexBespoke = (): boolean => {
	return getMatchingPath(rolexBespokePath)
}
