/* Helper */
import { fixPlus } from '@helpers/stringHelper'

/* Shared Types */
import { gatherFiltersType } from '@lib/types/Watch.models'
import { JSONObject } from '@lib/types/JSONObject.models'
import { pminDefault, pmaxDefault } from '@lib/effects/filterSortEffectConfig'
import { StringObject } from '@lib/types/Basic.models'
import { SelectedRefinementsType } from '@helpers/ocapiCustomAPIHelper/ocapiCustomAPIHelper.models'

/* Skip checked values? */
const skipChecked: string[] = ['limit']

/* Fixed Config */
const defaultType: string = 'Pre-Owned' // If not in below 2 configs, assume Pre-Owned (rlx-cpo, ns-jp, ns-pre)
const pdpTypes: StringObject = {
	rlx: 'Rolex', // New Rolex
}
const prefixTypes: StringObject = {
	'ns-new-': 'New', // New Watches
	'ns-jn-': 'Estate', // New Jewelry
}

/* Helper */
const watchesHelper = {
	filterSortPage: (
		searchParams: URLSearchParams,
		allowPages: boolean,
		allowFilters: string[]
	): [gatherFiltersType, string, number] => {
		const refinements: gatherFiltersType = []
		let sort: string = ''
		let page: number = 1
		let vals: string[] = []
		let pmin: string = ''
		let pmax: string = ''
		const checked: { [key: string]: boolean } = {}

		/* Params as URLSearchParams or JSONObject */
		const params =
			'forEach' in searchParams ? searchParams : Object.entries(searchParams)

		/* Set page and sort from params */
		params.forEach((value, key) => {
			if (typeof value === 'object') {
				key = value[0] as string
				value = value[1] as string
			}
			key = key.toString()
			if (key == 'refine') {
				Object.entries(value).forEach(([refineKey, refineVal]) => {
					if (refineVal && refineVal.length > 0) {
						const rootKey = refineKey.replace('c_', '')
						if (rootKey === 'price') {
							[pmin, pmax] = refineVal
								.replace('(', '')
								.replace(')', '')
								.split('..')
						} else if (
							!skipChecked.includes(rootKey) &&
							!skipChecked.includes(refineKey) &&
							allowFilters.includes(refineKey)
						) {
							if (Array.isArray(refineVal)) {
								refineVal
									.join('|')
									.split('|')
									.forEach((val) => {
										checked[rootKey + '__' + fixPlus(val)] = true
									})
							} else if (typeof refineVal === 'string') {
								checked[rootKey + '__' + fixPlus(refineVal)] = true
							}
							refinements.push([rootKey, fixPlus(refineVal)])
						}
					}
				})
			} else if (key.indexOf('refine') === -1) {
				value = value ? value.toString() : ''
				if (value) {
					if (key == 'page') {
						if (allowPages) {
							page = parseInt(value) || 1
							if (page < 1) {
								page = 1
							}
						}
					} else if (key == 'sort') {
						sort = value
					} else if (key == 'pmin') {
						pmin = value
					} else if (key == 'pmax') {
						pmax = value
					} else if (!skipChecked.includes(key)) {
						vals = value.split('|')
						if (vals.length > 0) {
							vals.forEach((val) => {
								checked[key + '__' + fixPlus(val)] = true
							})
							refinements.push([key, vals])
						}
					}
				}
			}
		})
		if (pmin || pmax) {
			refinements.push([
				'price',
				[pmin.toString() || pminDefault, pmax.toString() || pmaxDefault],
			])
		}

		return [refinements, sort, page]
	},
	pageStart: (page: number, per: number): number => {
		return page > 1 ? (page - 1) * per : 0
	},
	maxPage: (total: number, perPage: number): number => {
		return Math.ceil(total / perPage)
	},
	param: (params: URLSearchParams, key: string): string => {
		const paramsObj = params as unknown as JSONObject
		const val: string = paramsObj ? paramsObj[key]?.toString().trim() || '' : ''
		return val
	},
	scrub: (val: string): string => {
		return val
			.toString()
			.replace(/\+/g, ' ')
			.replace(/\%20/g, ' ')
			.replace(/\%2B/g, ' ')
	},
	status: (c_pdpType: string, productId: string): string => {
		let ret: string = ''
		if (c_pdpType && c_pdpType in pdpTypes) {
			ret = pdpTypes[c_pdpType]
		} else if (typeof productId === 'string') {
			Object.entries(prefixTypes).forEach(([prefix, type]) => {
				if (!ret && productId.indexOf(prefix) > -1) {
					ret = type
				}
			})
		}
		return ret || defaultType
	},
}

/* Named functions */
export function getFiltersSortPage(
	searchParams: URLSearchParams,
	allowPages: boolean,
	refinements: {
		attributeId: string
	}[],
	selectedRefinements: SelectedRefinementsType
): [gatherFiltersType, string, number] {
	const allowFilters: string[] = refinements
		? Object.values(refinements)
				.map((val) => {
					return val?.attributeId || ''
				})
				.filter((val) => val)
		: []
	if (selectedRefinements) {
		Object.keys(selectedRefinements).forEach((key) => {
			allowFilters.push(key)
		})
	}
	return watchesHelper.filterSortPage(searchParams, allowPages, allowFilters)
}
export function getPageStart(page: number, per: number): number {
	return watchesHelper.pageStart(page, per)
}
export function getMaxPage(total: number, perPage: number): number {
	return watchesHelper.maxPage(total, perPage)
}
export function getParamValue(
	searchParams: URLSearchParams,
	key: string
): string {
	return watchesHelper.param(searchParams, key)
}
export function scrubParamValue(val: string): string {
	return watchesHelper.scrub(val)
}
export function newOrPreOwned(c_pdpType: string, productId: string): string {
	return watchesHelper.status(c_pdpType, productId)
}
