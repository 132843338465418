import React from 'react'

/* Map */
import { icons } from './icon_map'

/* Components */
import InlineCSS from '@components/seo/InlineCSS'

/* Inline Styles */
import IconStyles from '!raw-loader!postcss-loader!sass-loader!./Icon.module.scss'

/* Props */
export interface IconProps {
	name: keyof typeof icons

	// Styles
	variant?: 'primary' | 'secondary' | 'muted' | 'stroke'
	size?: '14' | '16' | '20' | '24' | '32' | '44'
	isFilled?: boolean

	// Attributes
	className?: string // Implementation-specific classes for overrides & customizations

	ariaHidden?: boolean

	/*
	 * Icons are standalone if they are not decorative and not accomponied by text. In this case,
	 * they should be treated as images and require a role="img" attribute and a title.
	 * Reference: https://css-tricks.com/accessible-svg-icons/
	 */
	title?: string
}

export function Icon({
	name,
	variant = 'primary',
	size = '32',
	isFilled = false,
	className,
	ariaHidden,
	title,
}: IconProps): React.ReactElement {
	/* Icon */
	const { fillBased, path } = icons[name]

	/* Variations */
	const iconClasses = [
		'icon',
		`icon--${variant}`,
		`icon--${size}`,
		fillBased || isFilled ? `icon--filled` : '',
		fillBased ? 'icon--no-stroke' : '',
	].join(' ')

	return (
		<InlineCSS componentName={'Icon'} styles={IconStyles}>
			<svg
				viewBox={'0 0 24 24'}
				className={iconClasses}
				rawclassname={className}
				aria-hidden={ariaHidden}
				role={title ? 'img' : undefined}
			>
				{title && <title>{title}</title>}
				{path}
			</svg>
		</InlineCSS>
	)
}
