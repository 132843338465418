import { createContext, useContext } from 'react'

/* Shared Types */
import { CustomBasketItem } from '@lib/types/scapi/basket.models'
import { ExtendedScapiProduct } from '@lib/types/scapi/product.models'

/* Local Types */
export type BasketContextValues = {
	key: string
	offset: number | null
	total: number
	items: {
		[key: string]: number
	}
	basket: {
		items: CustomBasketItem[]
	}
}
export type BasketContextType = [
	BasketContextValues,
	React.Dispatch<React.SetStateAction<BasketContextValues>>,
	() => void,
	(
		product: ExtendedScapiProduct,
		price?: number
	) => Promise<{ success: boolean }>,
]

/* Basket Context Provider */
export const BasketContext = createContext<BasketContextType | null>(null)

/* Basket Context Hook */
export const useBasketContext = (): BasketContextType => {
	const currentBasketContext = useContext(BasketContext)

	if (!currentBasketContext) {
		throw new Error(
			'useBasketContext has to be used within <BasketContext.Provider>'
		)
	}

	return currentBasketContext
}
