import React from 'react'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

export const scrubLinks = (children: React.ReactNode): React.ReactNode => {
	/* Replace all a with SwitchLink */
	return React.Children.map(children, (child) => {
		if (React.isValidElement(child)) {
			if (child.type === 'a') {
				/* Scrub layout-brand- prefix from pre-owned family id? */
				let href: string = child.props.href || ''
				if (href.indexOf('/s/ns-company/') > -1) {
					href = href.split('/s/ns-company', 2)[1]
				}
				if (href && href.startsWith('/pre-owned/')) {
					href = href.replace(/^\/(.*)\/(.*)\/\1-\2-(.*)\//, '/$1/$2/$3/')
				}

				/* TBD - replace Search-Show and Product-Show links here or in the back-end? */

				return (
					<SwitchLink {...child.props} href={href}>
						{child.props.children}
					</SwitchLink>
				)
			}

			if (child.props.children) {
				return React.cloneElement(child, {
					children: scrubLinks(child.props.children),
				} as React.PropsWithChildren<React.ReactElement>)
			}
		}
		return child
	})
}

/* Replace legacy and current domains with relative links */
export const scrubDomains = (html: string): string => {
	if (html.indexOf('.com') > -1) {
		['watchuwant', 'thewatchbox', 'govbergwatches', 'the1916company'].forEach(
			(domain) => {
				['', 'www.', 'dw-stage.', 'dw-uat.'].forEach((subdomain) => {
					html = html.replace(
						new RegExp(`https?://${subdomain}${domain}.com/`, 'g'),
						'/'
					)
				})
			}
		)
	}

	return html
}
