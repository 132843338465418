const config = {
	accordionExclusions: {
		SG: ['patek-philippe'],
	},
	navExclusions: {
		SG: ['patek-philippe'],
	},
	sortExclusions: {
		watches: 'best-matches-watches',
		'patek-philippe': 'best-matches-watches',
		'pre-owned': 'best-matches-pre-owned',
		jewelry: 'best-matches-jewelry',
		'rolex-certified-pre-owned': 'best-matches-cpo',
		default: 'most-popular',
	},
}

export const accordionExclusions = config.accordionExclusions as {
	[key: string]: string[]
}
export const navExclusions = config.navExclusions as { [key: string]: string[] }
export const sortExclusions = config.sortExclusions as { [key: string]: string }

export default config
